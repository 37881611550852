class WebVitals {
    constructor(performance, clock, PerformanceObserverClass) {
        this.performance = performance;
        this.clock = clock;
        this.observers = [];
        if (PerformanceObserverClass && Array.isArray(PerformanceObserverClass.supportedEntryTypes)) {
            const supportedEntryTypes = PerformanceObserverClass.supportedEntryTypes;
            if (supportedEntryTypes.includes('largest-contentful-paint')) {
                this.observeLargestContentfulPaint(PerformanceObserverClass);
            }
            if (supportedEntryTypes.includes('layout-shift')) {
                this.observeLayoutShift(PerformanceObserverClass);
            }
        }
    }
    attachTo(span) {
        const firstContentfulPaint = this.firstContentfulPaint();
        if (firstContentfulPaint) {
            span.addEvent('fcp', firstContentfulPaint);
        }
        const timeToFirstByte = this.timeToFirstByte();
        if (timeToFirstByte) {
            span.addEvent('ttfb', timeToFirstByte);
        }
        const firstInputDelay = this.firstInputDelay();
        if (firstInputDelay) {
            span.addEvent('fid_start', firstInputDelay.start);
            span.addEvent('fid_end', firstInputDelay.end);
        }
        if (this.cumulativeLayoutShift) {
            span.setAttribute('bugsnag.metrics.cls', this.cumulativeLayoutShift);
        }
        if (this.largestContentfulPaint) {
            span.addEvent('lcp', this.largestContentfulPaint);
        }
        // as there is only 1 page load span, we don't need to keep observing
        // performance events, so can disconnect from any observers we've registered
        for (const observer of this.observers) {
            observer.disconnect();
        }
    }
    firstContentfulPaint() {
        const entries = this.performance.getEntriesByName('first-contentful-paint', 'paint');
        const entry = Array.isArray(entries) && entries[0];
        if (entry) {
            return entry.startTime;
        }
    }
    timeToFirstByte() {
        const entries = this.performance.getEntriesByType('navigation');
        const entry = Array.isArray(entries) && entries[0];
        let responseStart;
        if (entry) {
            responseStart = entry.responseStart;
        }
        else {
            responseStart = this.performance.timing.responseStart - this.performance.timing.navigationStart;
        }
        // only use responseStart if it's valid (between 0 and the current time)
        // any other value cannot be valid because it would mean the response
        // started immediately or hasn't happened yet!
        if (responseStart > 0 && responseStart <= this.clock.now()) {
            return responseStart;
        }
    }
    firstInputDelay() {
        const entries = this.performance.getEntriesByType('first-input');
        const entry = Array.isArray(entries) && entries[0];
        if (entry) {
            return {
                start: entry.startTime,
                end: entry.processingStart
            };
        }
    }
    observeLargestContentfulPaint(PerformanceObserverClass) {
        const observer = new PerformanceObserverClass((list) => {
            const entries = list.getEntries();
            if (entries.length > 0) {
                // Use the latest LCP candidate
                this.largestContentfulPaint = entries[entries.length - 1].startTime;
            }
        });
        observer.observe({ type: 'largest-contentful-paint', buffered: true });
        this.observers.push(observer);
    }
    observeLayoutShift(PerformanceObserverClass) {
        let session;
        const observer = new PerformanceObserverClass((list) => {
            for (const entry of list.getEntries()) {
                // ignore entries with recent input as it's likely the layout shifted due
                // to user input and this metric only cares about unexpected layout
                // shifts
                if (entry.hadRecentInput) {
                    continue;
                }
                // include this entry in the current session if we have a current session
                // and this entry fits into the session window (it occurred less than 1
                // second after the previous entry and the session duration is less than
                // 5 seconds), otherwise start a new session
                if (session &&
                    entry.startTime - session.previousStartTime < 1000 &&
                    entry.startTime - session.firstStartTime < 5000) {
                    session.value += entry.value;
                    session.previousStartTime = entry.startTime;
                }
                else {
                    session = {
                        value: entry.value,
                        firstStartTime: entry.startTime,
                        previousStartTime: entry.startTime
                    };
                }
            }
            if (session &&
                (this.cumulativeLayoutShift === undefined || session.value > this.cumulativeLayoutShift)) {
                this.cumulativeLayoutShift = session.value;
            }
        });
        observer.observe({ type: 'layout-shift', buffered: true });
        this.observers.push(observer);
    }
}

export { WebVitals };
