function createBrowserBackgroundingListener(window) {
    const callbacks = [];
    let state = window.document.visibilityState === 'hidden'
        ? 'in-background'
        : 'in-foreground';
    const backgroundingListener = {
        onStateChange(backgroundingListenerCallback) {
            callbacks.push(backgroundingListenerCallback);
            // trigger the callback immediately if the document is already 'hidden'
            if (state === 'in-background') {
                backgroundingListenerCallback(state);
            }
        }
    };
    const backgroundStateChanged = (newState) => {
        if (state === newState)
            return;
        state = newState;
        for (const callback of callbacks) {
            callback(state);
        }
    };
    window.document.addEventListener('visibilitychange', function () {
        const newState = window.document.visibilityState === 'hidden'
            ? 'in-background'
            : 'in-foreground';
        backgroundStateChanged(newState);
    });
    // some browsers don't fire the visibilitychange event when the page is suspended,
    // so we also listen for pagehide and pageshow events
    window.addEventListener('pagehide', function () {
        backgroundStateChanged('in-background');
    });
    window.addEventListener('pageshow', function () {
        backgroundStateChanged('in-foreground');
    });
    return backgroundingListener;
}

export { createBrowserBackgroundingListener as default };
